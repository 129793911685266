export default function statColors() {
  const statusColors = {
    Draft: {
      title: 'Draft',
      class: 'light-primary',
    },
    'Draft Lengkap': {
      title: 'Draft Lengkap',
      class: 'light-info',
    },
    'Sedang Rekomendasi': {
      title: 'Sedang Rekomendasi',
      class: 'light-warning',
    },
    'Sedang Verifikasi Teknis': {
      title: 'Sedang Verifikasi Teknis',
      class: 'light-warning',
    },
    'Menunggu Verifikasi': {
      title: 'Menunggu Verifikasi Teknis',
      class: 'light-secondary',
    },
    'Menunggu Rekomendasi': {
      title: 'Menunggu Rekomendasi',
      class: 'light-warning',
    },
    'Perlu Perbaikan': {
      title: 'Perlu Perbaikan',
      class: 'light-danger',
    },
    'MODIFY-NEED': {
      title: 'Perlu Perbaikan',
      class: 'light-danger',
    },
    VERIFY: {
      title: 'Selesai Verifikasi',
      class: 'light-success',
    },
    'Menunggu Pembahasan': {
      title: 'Menunggu Pembahasan',
      class: 'light-warning',
    },
    'Sedang Proses Persetujuan Eselon I': {
      title: 'Sedang Proses Persetujuan Eselon I',
      class: 'light-success',
    },
    'Menunggu Tanggapan IPPD': {
      title: 'Menunggu Tanggapan IPPD',
      class: 'light-primary',
    },
    'Menunggu Konfirmasi IPPD': {
      title: 'Proses Berita Acara',
      class: 'light-warning',
    },
    'Selesai Konfirmasi IPPD': {
      title: 'Proses Berita Acara',
      class: 'light-warning',
    },
    'Surat Selesai': {
      title: 'Surat Selesai',
      class: 'light-info',
    },
    'Clearance Selesai': {
      title: 'Clearance Selesai',
      class: 'light-success',
    },
    'Menunggu Clearance Selesai': {
      title: 'Menunggu Clearance Selesai',
      class: 'light-secondary',
    },
    'Proses Berita Acara': {
      title: 'Proses Berita Acara',
      class: 'light-warning',
    },
  }

  const buttonColors = {
    Draft: {
      title: 'Draft',
      class: 'primary',
    },
    'Draft Lengkap': {
      title: 'Draft Lengkap',
      class: 'info',
    },
    'Sedang Rekomendasi': {
      title: 'Sedang Rekomendasi',
      class: 'warning',
    },
    'Sedang Verifikasi Teknis': {
      title: 'Sedang Verifikasi Teknis',
      class: 'warning',
    },
    'Menunggu Verifikasi': {
      title: 'Menunggu Verifikasi Teknis',
      class: 'secondary',
    },
    'Menunggu Rekomendasi': {
      title: 'Menunggu Rekomendasi',
      class: 'warning',
    },
    'Perlu Perbaikan': {
      title: 'Perlu Perbaikan',
      class: 'danger',
    },
    'MODIFY-NEED': {
      title: 'Perlu Perbaikan',
      class: 'danger',
    },
    VERIFY: {
      title: 'Selesai Verifikasi',
      class: 'success',
    },
    'Menunggu Pembahasan': {
      title: 'Menunggu Pembahasan',
      class: 'warning',
    },
    'Sedang Proses Persetujuan Eselon I': {
      title: 'Sedang Proses Persetujuan Eselon I',
      class: 'success',
    },
    'Menunggu Tanggapan IPPD': {
      title: 'Menunggu Tanggapan IPPD',
      class: 'primary',
    },
    'Menunggu Konfirmasi IPPD': {
      title: 'Proses Berita Acara',
      class: 'info',
    },
    'Selesai Konfirmasi IPPD': {
      title: 'Proses Berita Acara',
      class: 'info',
    },
    'Surat Selesai': {
      title: 'Surat Selesai',
      class: 'info',
    },
    'Clearance Selesai': {
      title: 'Clearance Selesai',
      class: 'success',
    },
    'Menunggu Clearance Selesai': {
      title: 'Menunggu Clearance Selesai',
      class: 'secondary',
    },
    'Proses Berita Acara': {
      title: 'Proses Berita Acara',
      class: 'info',
    },
  }

  const verificationColors = {
    1: {
      title: 'Terverifikasi',
      class: 'light-info',
    },
    '-1': {
      title: 'Perlu Perbaikan',
      class: 'light-warning',
    },
  }

  const activityColors = {
    'Sudah Selesai': {
      title: 'Sudah Selesai',
      class: 'light-success',
    },
    'Belum Selesai': {
      title: 'Belum Selesai',
      class: 'light-warning',
    },
  }

  const activityColorsNew = [
    {
      title: 'Belum',
      class: 'light-warning',
    },
    {
      title: 'Sudah',
      class: 'light-success',
    },
  ]

  const clearanceColors = {
    continue: {
      title: 'Dilanjutkan',
      class: 'light-success',
    },
    pending: {
      title: 'Tidak Dilanjutkan',
      class: 'light-warning',
    },
    Dilanjutkan: {
      title: 'Dilanjutkan',
      class: 'success',
    },
    'Tidak Ada Rekomendasi': {
      title: 'Tidak Ada Rekomendasi',
      class: 'secondary',
    },
    'Tidak Dilanjutkan': {
      title: 'Tidak Dilanjutkan',
      class: 'danger',
    },
    'Dispute - Tidak Dilanjutkan': {
      title: 'Dispute',
      class: 'warning',
    },
    'Belum Selesai': {
      title: 'Belum Selesai',
      class: 'secondary',
    },
    Dispute: {
      title: 'Dispute',
      class: 'warning',
    },
    'Menunggu Pembahasan': {
      title: 'Menunggu Pembahasan',
      class: 'warning',
    },
  }

  return {
    activityColors,
    buttonColors,
    activityColorsNew,
    clearanceColors,
    statusColors,
    verificationColors,
  }
}
